<template>
  <article class="pu-details-kpis wx-tile-panel">
    <h3 v-if="!isXs" class="mb-6 text-center">{{ $t("overview.details.kpis.title") }}</h3>

    <ul>
      <li
        v-for="(kpi, index) in productionUnitKpis"
        :key="'detailsKpis-' + index"
        class="kpi pa-3"
        :class="{ 'mb-4': index < productionUnitKpis.length - 1 }"
      >
        <div class="kpi-title-and-target mr-2">
          <span class="mr-1">{{ i18nKpiName(kpi.name) }}</span>
          <span v-if="kpi.target" class="wx-subtle-text wx-typo-sm">
            ({{ $t("overview.details.kpis.target") }} {{ kpi.target }}{{ isPercentTarget(kpi.name) ? "%" : "" }})
          </span>
        </div>
        <span class="kpi-value wx-typo-h3 mr-2">{{ formatKpi(kpi.valueString) }}</span>
        <span class="kpi-indicator" :class="kpiStatus(kpi)"></span>
      </li>
    </ul>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
import Helpers, { dash } from "@/helpers";
import Tiles from "@/components/Tiles";
import PackageFeatures from "@/components/PackageFeatures";

export default {
  name: "PUDetailsKpis",
  computed: {
    ...mapGetters("overview", [
      "activeProductionUnitIdForDetails",
      "productionUnitsStateAndKpis",
      "productionUnitsSpeed",
    ]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    productionUnitKpis() {
      const puId = this.activeProductionUnitIdForDetails;
      const puStateAndKpis = this.productionUnitsStateAndKpis.find((p) => p.puId === puId);
      const puSpeed = this.productionUnitsSpeed.find((p) => p.production_unit_id === puId);
      const puSpeedInUnitsPerHour = puSpeed ? puSpeed.product_speed : null;
      const puSpeedInUnitsPerMinute = puSpeedInUnitsPerHour ? puSpeedInUnitsPerHour / 60.0 : null;

      if (puStateAndKpis) {
        let raterPerMinuteRoundedTarget = Helpers.getRoundedValueOrDash(
          puStateAndKpis.ratePerMinuteTargetValue,
          this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
        );
        let raterPerMinuteTarget = raterPerMinuteRoundedTarget === dash ? null : raterPerMinuteRoundedTarget;
        // Rows in the key Performance Indications when clicking on a pu
        return [
          {
            name: "oee",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.oeeValue,
              this.puHasRequiredFeature(puId, Tiles.oee.requiredFeature),
            ),
            value: puStateAndKpis.oeeValue,
            target: puStateAndKpis.oeeTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.oee.requiredFeature),
          },
          {
            name: "availability",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.availabilityValue,
              this.puHasRequiredFeature(puId, Tiles.availability.requiredFeature),
            ),
            value: puStateAndKpis.availabilityValue,
            target: puStateAndKpis.availabilityTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.availability.requiredFeature),
          },
          {
            name: "performance",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.performanceValue,
              this.puHasRequiredFeature(puId, Tiles.performance.requiredFeature),
            ),
            value: puStateAndKpis.performanceValue,
            target: puStateAndKpis.performanceTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.performance.requiredFeature),
          },
          {
            name: "quality",
            valueString: Helpers.getRoundedPercentageOrDash(puStateAndKpis.qualityValue),
            value: puStateAndKpis.qualityValue,
            target: this.puHasRequiredFeature(puId, PackageFeatures.reject) ? puStateAndKpis.qualityTarget : null,
            authorized: this.puHasRequiredFeature(puId, Tiles.quality.requiredFeature),
          },
          {
            name: "ooe",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.ooeValue,
              this.puHasRequiredFeature(puId, Tiles.ooe.requiredFeature),
            ),
            value: puStateAndKpis.ooeValue,
            target: null,
            authorized: this.puHasRequiredFeature(puId, Tiles.ooe.requiredFeature),
          },
          {
            name: "allProductQuantity",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.allProductQuantityValue,
              this.puHasRequiredFeature(puId, Tiles.allProductsQuantity.requiredFeature),
            ),
            value: puStateAndKpis.allProductQuantityValue,
            target: null,
            authorized: this.puHasRequiredFeature(puId, Tiles.allProductsQuantity.requiredFeature),
          },
          {
            name: "currentProductQuantity",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.currentProductQuantityValue,
              this.puHasRequiredFeature(puId, Tiles.currentProductQuantity.requiredFeature),
            ),
            value: puStateAndKpis.currentProductQuantityValue,
            target: null,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductQuantity.requiredFeature),
          },
          {
            name: "productionRunQuantity",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.currentProductionRunQuantityValue,
              this.puHasRequiredFeature(puId, Tiles.currentProductQuantity.requiredFeature),
            ),
            value: puStateAndKpis.currentProductionRunQuantityValue,
            target: null,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductQuantity.requiredFeature),
          },
          {
            name: "ratePerHour",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.ratePerHourValue,
              this.puHasRequiredFeature(puId, Tiles.currentProductThroughput.requiredFeature),
            ),
            value: puStateAndKpis.ratePerHourValue,
            target: puStateAndKpis.ratePerHourTargetValue,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductThroughput.requiredFeature),
          },
          {
            name: "ratePerMinute",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.ratePerMinuteValue,
              this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
            ),
            value: puStateAndKpis.ratePerMinuteValue,
            target: raterPerMinuteTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
          },
          {
            name: "speed5Minute",
            valueString: Helpers.getRoundedValueOrDash(
              puSpeedInUnitsPerHour,
              this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
            ),
            value: puSpeedInUnitsPerHour,
            target: puStateAndKpis.ratePerHourTargetValue,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
          },
          {
            name: "speed5MinutePerMinute",
            valueString: Helpers.getRoundedValueOrDash(
              puSpeedInUnitsPerMinute,
              this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
            ),
            value: puSpeedInUnitsPerMinute,
            target: raterPerMinuteTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
          },
        ];
      } else {
        return [];
      }
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    isPercentTarget(kpiName) {
      let percentTargets = ["oee", "availability", "performance", "quality"];
      return percentTargets.includes(kpiName);
    },
    formatKpi(kpiValue) {
      return kpiValue !== null ? kpiValue : dash;
    },
    kpiStatus(kpi) {
      if (
        kpi.target === null ||
        kpi.target === undefined ||
        kpi.value === null ||
        !kpi.authorized ||
        isNaN(kpi.value) ||
        isNaN(kpi.target)
      ) {
        return "unknown";
      }
      if ((kpi.name === "speed5Minute" || kpi.name === "speed5MinutePerMinute") && kpi.value === 0) {
        return "unknown";
      }
      const value = kpi.value - kpi.target;
      let status;
      if (value >= 0) {
        status = "good";
      } else if (value >= -5) {
        status = "average";
      } else {
        status = "bad";
      }
      return status;
    },
    i18nKpiName(kpiName) {
      switch (kpiName) {
        case "oee":
          return this.$t("overview.details.kpis.oee");
        case "availability":
          return this.$t("overview.details.kpis.availability");
        case "performance":
          return this.$t("overview.details.kpis.performance");
        case "quality":
          return this.$t("overview.details.kpis.quality");
        case "allProductQuantity":
          return this.$t("overview.details.kpis.allProductQuantity");
        case "currentProductQuantity":
          return this.$t("overview.details.kpis.currentProductQuantity");
        case "ratePerHour":
          return this.$t("overview.details.kpis.ratePerHour");
        case "ratePerMinute":
          return this.$t("overview.details.kpis.ratePerMinute");
        case "speed5Minute":
          return this.$t("overview.details.kpis.speed5Minute");
        case "speed5MinutePerMinute":
          return this.$t("overview.details.kpis.speed5MinutePerMinute");
        case "productionRunQuantity":
          return this.$t("overview.details.kpis.productionRunQuantity");
        case "ooe":
          return this.$t("overview.details.kpis.ooe");
        default:
          console.warn("Missing case for KPI " + kpiName);
          return kpiName;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-tile-panel {
  .kpi {
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-sm);
  }
}

.kpi {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);
}

.kpi-title-and-target {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-shrink: 1;
}

.kpi-value {
  margin-left: auto;
  flex-shrink: 0;
}

.kpi-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid transparent;
  flex-shrink: 0;

  &:before {
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
  }

  &.good {
    border-color: var(--color-success);
    &:before {
      background: var(--color-success-alpha);
    }
  }

  &.average {
    border-color: var(--color-warning);
    &:before {
      background: var(--color-warning-alpha);
    }
  }

  &.bad {
    border-color: var(--color-error);
    &:before {
      background: var(--color-error-alpha);
    }
  }

  &.unknown {
    border-color: var(--color-secondary);
    &:before {
      background: var(--color-secondary-alpha);
    }
  }

  &.no-metric {
    border-color: var(--color-neutral);
    &:before {
      background: var(--color-neutral-alpha);
    }
  }
}
</style>
