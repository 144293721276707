<!--
Identified <span> tags are wrappers to get
"text-overflow: ellipsis" to work with a fluid width.
-->
<template>
  <v-card
    :wxid="$options.name"
    :title="getProductionUnitHoverTitle"
    tag="article"
    class="pu-card"
    :class="[isDisconnected ? 'disconnected' : puStatus, detailsActive ? 'details-active' : false]"
  >
    <overview-tag-selector :pu-id="puId" class="mb-2" />

    <section :class="{ 'is-presenter': isPresenter, 'is-fullscreen': isFullScreen }" class="card-body">
      <h3 class="ellipsis mb-1 wx-typo-h2">
        <!-- span for text-overflow: ellipsis -->
        <span>{{ puName }}</span>
      </h3>

      <template v-if="isDisconnected">
        <p class="ellipsis pu-subheader wx-typo-h3">
          <!-- span for text-overflow: ellipsis -->
          <span>{{ $t("overview.productionUnit.disconnected") }}</span>
        </p>
        <div class="wx-typo-h1">{{ disconnectedDuration }}</div>
        <div class="mt-3 wx-subtle-text wx-typo-h3">{{ $t("overview.productionUnit.disconnectedLastMeasure") }}:</div>
        <div class="wx-subtle-text wx-typo-h3">{{ disconnectedStartDate }}</div>
      </template>

      <template v-else>
        <p class="ellipsis pu-subheader wx-typo-h3">
          <!-- span for text-overflow: ellipsis -->
          <span class="wx-subtle-text">{{ getProductSkuAndName }}</span>
        </p>
        <div class="kpi-circle" :class="kpiCircleClass">
          <div class="kpi-value wx-typo-h1"> {{ getKpiValue }}<span class="wx-typo-h3" v-if="oeeValue"></span> </div>
          <div class="kpi-label wx-subtle-text wx-typo-h2">{{ getKpiTarget }}</div>
        </div>
        <div class="kpi-name wx-typo-h3">{{ getKpiName }}</div>
      </template>
    </section>
    <footer v-if="!isPresenter" class="card-footer">
      <wx-btn-standard
        @click.stop="toggleDetails(index)"
        :title="$t('overview.productionUnit.buttonHoverTitle')"
        :class="detailsActive ? 'clicked' : 'unclicked'"
        class="mt-3 btn-pu-details-control"
        outlined
        block
      >
        <v-icon class="detailsBtnIcon" :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-chevron-down</v-icon>
      </wx-btn-standard>
    </footer>
    <tile-disconnected-dialog v-model="dialogState" @closeDialog="closeDialog" />
  </v-card>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import { mapActions, mapGetters } from "vuex";
import RouteService from "@/router/RouteService";
import TileDisconnectedDialog from "@/components/dashboard/tiles/TileDisconnectedDialog";
import * as TimeUtils from "@/store/TimeUtils";
import { Duration } from "luxon";
import { DATE_TIME_FORMAT_NO_TIMEZONE } from "@/store/TimeUtils";
import Helpers, { dash } from "@/helpers";
import Tiles from "@/components/Tiles";
import PackageFeatures from "@/components/PackageFeatures";
import OverviewTagSelector from "@/components/ui/tagselector/OverviewTagSelector.vue";

const OEE = 0;
const AVAILABILITY = 1;
const PERFORMANCE = 2;
const QUALITY = 3;
const OOE = 4;
const ALL_PRODUCT_QUANTITY = 5;
const CURRENT_PRODUCT_QUANTITY = 6;
const RATE_PER_HOUR = 7;
const RATE_PER_MINUTE = 8;
const SPEED5MINUTES_PER_HOUR = 9;
const SPEED5MINUTES_PER_MINUTE = 10;

export default {
  name: "PUItem",
  components: { OverviewTagSelector, WxBtnStandard, TileDisconnectedDialog },
  props: {
    puId: {
      type: String,
      required: true,
    },
    puName: {
      type: String,
      required: true,
    },
    puStatus: {
      type: String,
      required: true,
    },
    productSku: {
      type: String,
      required: false,
      default: null,
    },
    productName: {
      type: String,
      required: false,
      default: null,
    },
    kpiSelected: {
      type: Number,
      required: false,
      default: null,
    },
    oeeValue: {
      type: Number,
      required: false,
      default: null,
    },
    oeeTarget: {
      type: Number,
      required: false,
      default: null,
    },
    ooeValue: {
      type: Number,
      required: false,
      default: null,
    },
    availabilityValue: {
      type: Number,
      required: false,
      default: null,
    },
    availabilityTarget: {
      type: Number,
      required: false,
      default: null,
    },
    performanceValue: {
      type: Number,
      required: false,
      default: null,
    },
    performanceTarget: {
      type: Number,
      required: false,
      default: null,
    },
    qualityValue: {
      type: Number,
      required: false,
      default: null,
    },
    qualityTarget: {
      type: Number,
      required: false,
      default: null,
    },
    allProductQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    currentProductQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerHourValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerHourTarget: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerMinuteValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerMinuteTarget: {
      type: Number,
      required: false,
      default: null,
    },
    speedFiveMinuteValue: {
      type: Number,
      required: false,
      default: null,
    },
    speedFiveMinuteValuePerMinute: {
      type: Number,
      required: false,
      default: null,
    },
    isDisconnected: {
      type: Boolean,
      required: true,
    },
    isDisconnectedSince: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    detailsActive: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disconnectedInterval: null,
      disconnectedDuration: "00:00:00",
      disconnectedStartDate: "",
      dialogState: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    ...mapGetters("user", ["isPresenter"]),
    getProductionUnitHoverTitle() {
      return this.$t("overview.productionUnit.cardHoverTitle", { puName: this.puName });
    },
    kpiCircleClass() {
      const classes = { "is-fullscreen": this.isFullScreen };
      classes[this.getKpiStatus] = true;
      return classes;
    },
    getProductSkuAndName() {
      if (this.productSku == null) {
        return this.$t("overview.productionUnit.noProduct");
      } else {
        let name = this.productName ? " / " + this.productName : "";
        return this.productSku + name;
      }
    },
    getKpiValue() {
      // Value in PU card
      if (this.kpiSelected === OEE) {
        return Helpers.getRoundedPercentageOrDash(this.oeeValue, true);
      } else if (this.kpiSelected === AVAILABILITY) {
        return Helpers.getRoundedPercentageOrDash(this.availabilityValue, true);
      } else if (this.kpiSelected === PERFORMANCE) {
        return Helpers.getRoundedPercentageOrDash(this.performanceValue, true);
      } else if (this.kpiSelected === QUALITY) {
        return Helpers.getRoundedPercentageOrDash(this.qualityValue, true);
      } else if (this.kpiSelected === ALL_PRODUCT_QUANTITY) {
        return Helpers.getRoundedValueOrDash(this.allProductQuantityValue, true);
      } else if (this.kpiSelected === CURRENT_PRODUCT_QUANTITY) {
        return Helpers.getRoundedValueOrDash(this.currentProductQuantityValue, true);
      } else if (this.kpiSelected === RATE_PER_HOUR) {
        return Helpers.getRoundedValueOrDash(
          this.ratePerHourValue,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughput.requiredFeature),
        );
      } else if (this.kpiSelected === RATE_PER_MINUTE) {
        return Helpers.getRoundedValueOrDash(
          this.ratePerMinuteValue,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
        );
      } else if (this.kpiSelected === SPEED5MINUTES_PER_HOUR) {
        return Helpers.getRoundedValueOrDash(
          this.speedFiveMinuteValue,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5m.requiredFeature),
        );
      } else if (this.kpiSelected === SPEED5MINUTES_PER_MINUTE) {
        return Helpers.getRoundedValueOrDash(
          this.speedFiveMinuteValuePerMinute,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerMinute.requiredFeature),
        );
      } else if (this.kpiSelected === OOE) {
        return Helpers.getRoundedPercentageOrDash(
          this.ooeValue,
          this.puHasRequiredFeature(this.puId, Tiles.ooe.requiredFeature),
        );
      }
      return Helpers.getRoundedPercentageOrDash(this.oeeValue, true);
    },
    getKpiTarget() {
      // Target in PU card
      switch (this.kpiSelected) {
        case OEE:
          return Helpers.getRoundedPercentageOrDash(this.oeeTarget);
        case AVAILABILITY:
          return Helpers.getRoundedPercentageOrDash(this.availabilityTarget);
        case PERFORMANCE: {
          if (this.puHasRequiredFeature(this.puId, Tiles.performance.requiredFeature)) {
            return Helpers.getRoundedPercentageOrDash(this.performanceTarget);
          } else {
            return dash;
          }
        }
        case QUALITY: {
          // Reject module is necessary to show the Quality target
          if (this.puHasRequiredFeature(this.puId, PackageFeatures.reject)) {
            return Helpers.getRoundedPercentageOrDash(this.qualityTarget);
          } else {
            return dash;
          }
        }
        case ALL_PRODUCT_QUANTITY:
          return null;
        case CURRENT_PRODUCT_QUANTITY:
          return null;
        case RATE_PER_HOUR: {
          if (this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughput.requiredFeature)) {
            return Helpers.getRoundedValueOrDash(this.ratePerHourTarget);
          } else {
            return dash;
          }
        }
        case RATE_PER_MINUTE: {
          if (this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerMinute.requiredFeature)) {
            return Helpers.getRoundedValueOrDash(this.ratePerMinuteTarget);
          } else {
            return dash;
          }
        }
        case SPEED5MINUTES_PER_HOUR: {
          if (this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5m.requiredFeature)) {
            return Helpers.getRoundedValueOrDash(this.ratePerHourTarget);
          } else {
            return dash;
          }
        }
        case SPEED5MINUTES_PER_MINUTE: {
          if (this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerMinute.requiredFeature)) {
            return Helpers.getRoundedValueOrDash(this.ratePerMinuteTarget);
          } else {
            return dash;
          }
        }
        case OOE:
          return null;
        default:
          return null;
      }
    },
    getKpiStatus() {
      // The circle in the PU card
      let kpiValue;
      let kpiTarget;
      if (this.kpiSelected === OEE) {
        kpiValue = this.oeeValue;
        kpiTarget = this.oeeTarget;
      } else if (this.kpiSelected === AVAILABILITY) {
        kpiValue = this.availabilityValue;
        kpiTarget = this.availabilityTarget;
      } else if (this.kpiSelected === PERFORMANCE) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.performance.requiredFeature)) {
          return "no-metric";
        }
        kpiValue = this.performanceValue;
        kpiTarget = this.performanceTarget;
      } else if (this.kpiSelected === QUALITY) {
        // Reject module is necessary to show the Quality status color
        if (!this.puHasRequiredFeature(this.puId, PackageFeatures.reject)) {
          return "no-metric";
        }
        kpiValue = this.qualityValue;
        kpiTarget = this.qualityTarget;
      } else if (this.kpiSelected === ALL_PRODUCT_QUANTITY) {
        kpiValue = this.allProductQuantityValue;
        kpiTarget = null;
      } else if (this.kpiSelected === CURRENT_PRODUCT_QUANTITY) {
        kpiValue = this.currentProductQuantityValue;
        kpiTarget = null;
      } else if (this.kpiSelected === RATE_PER_HOUR) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughput.requiredFeature)) {
          return "no-metric";
        }
        kpiValue = this.ratePerHourValue;
        kpiTarget = this.ratePerHourTarget;
      } else if (this.kpiSelected === RATE_PER_MINUTE) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerMinute.requiredFeature)) {
          return "no-metric";
        }
        kpiValue = this.ratePerMinuteValue;
        kpiTarget = this.ratePerMinuteTarget;
      } else if (this.kpiSelected === SPEED5MINUTES_PER_HOUR) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5m.requiredFeature)) {
          return "no-metric";
        }
        if (this.speedFiveMinuteValuePerMinute === null || this.speedFiveMinuteValuePerMinute === 0) {
          return "no-metric";
        } else {
          kpiValue = this.speedFiveMinuteValue;
          kpiTarget = this.ratePerHourTarget;
        }
      } else if (this.kpiSelected === SPEED5MINUTES_PER_MINUTE) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerMinute.requiredFeature)) {
          return "no-metric";
        }
        if (this.speedFiveMinuteValuePerMinute === null || this.speedFiveMinuteValuePerMinute === 0) {
          return "no-metric";
        } else {
          kpiValue = this.speedFiveMinuteValuePerMinute;
          kpiTarget = this.ratePerMinuteTarget;
        }
      } else if (this.kpiSelected === OOE) {
        kpiValue = this.ooeValue;
        kpiTarget = null;
      } else {
        kpiValue = this.oeeValue;
        kpiTarget = this.oeeTarget;
      }

      if (kpiValue == null) return "no-metric";

      let value;
      switch (this.kpiSelected) {
        case RATE_PER_HOUR:
        case RATE_PER_MINUTE:
        case SPEED5MINUTES_PER_HOUR:
        case SPEED5MINUTES_PER_MINUTE: {
          value = kpiValue - kpiTarget;
          break;
        }
        default: {
          if (kpiTarget !== null && kpiTarget > 0) {
            value = 100.0 * ((kpiValue - kpiTarget) / kpiTarget);
          } else {
            return "no-metric";
          }
        }
      }
      let status;
      if (value >= 0) {
        status = "good";
      } else if (value >= -5) {
        status = "average";
      } else {
        status = "bad";
      }
      return status;
    },
    getKpiName() {
      if (this.kpiSelected === OEE) {
        return this.$t("overview.metrics.oee");
      } else if (this.kpiSelected === AVAILABILITY) {
        return this.$t("overview.metrics.availability");
      } else if (this.kpiSelected === PERFORMANCE) {
        return this.$t("overview.metrics.performance");
      } else if (this.kpiSelected === QUALITY) {
        return this.$t("overview.metrics.quality");
      } else if (this.kpiSelected === ALL_PRODUCT_QUANTITY) {
        return this.$t("overview.metrics.allProductQuantity");
      } else if (this.kpiSelected === CURRENT_PRODUCT_QUANTITY) {
        return this.$t("overview.metrics.currentProductQuantity");
      } else if (this.kpiSelected === RATE_PER_HOUR) {
        return this.$t("overview.metrics.ratePerHour");
      } else if (this.kpiSelected === RATE_PER_MINUTE) {
        return this.$t("overview.metrics.ratePerMinute");
      } else if (this.kpiSelected === SPEED5MINUTES_PER_HOUR) {
        return this.$t("overview.metrics.speed5Minute");
      } else if (this.kpiSelected === SPEED5MINUTES_PER_MINUTE) {
        return this.$t("overview.metrics.speed5MinutePerMinute");
      } else if (this.kpiSelected === OOE) {
        return this.$t("overview.metrics.ooe");
      } else {
        return this.$t("overview.metrics.oee");
      }
    },
    buttonHoverTitle() {
      return this.$t("overview.productionUnit.buttonHoverTitle");
    },
  },
  watch: {
    isDisconnected: {
      immediate: true,
      handler() {
        if (this.isDisconnected) {
          this.startDisconnectedInterval();
        } else {
          clearInterval(this.disconnectedInterval);
        }
      },
    },
  },
  methods: {
    ...mapActions("dashboard", ["setActiveProductionUnitId", "setActiveTabIndex"]),
    goToDashboard() {
      if (this.isDisconnected) {
        this.openDialog();
      } else {
        // Set every fields necessary to load the dashboard for the RIGHT production unit!
        this.setActiveProductionUnitId(this.puId);
        this.$router.push(RouteService.toDashboard(this.puId));
      }
    },
    startDisconnectedInterval() {
      this.setDisconnectedDuration();
      this.disconnectedInterval = setInterval(() => this.setDisconnectedDuration(), 1000);
    },
    setDisconnectedDuration() {
      const milliseconds = Date.now() - this.isDisconnectedSince;
      this.disconnectedDuration = Duration.fromMillis(milliseconds).toFormat("hh:mm:ss");
      this.disconnectedStartDate = TimeUtils.fromEpochMillis(
        this.isDisconnectedSince,
        this.activeFactory.timezone,
        DATE_TIME_FORMAT_NO_TIMEZONE,
      );
    },
    openDialog() {
      this.dialogState = true;
    },
    closeDialog() {
      this.dialogState = false;
    },
    toggleDetails(index) {
      this.$emit("toggleDetails", { production_unit_id: this.puId, index: index });
    },
  },
  beforeDestroy() {
    clearInterval(this.disconnectedInterval);
  },
};
</script>

<style lang="scss" scoped>
// local var
$tilesBorderColor: transparent;
$tilesBorderValue: 0;
$tilesBorderWidth: #{$tilesBorderValue}px;
$doubleTilesBordersWidth: #{(2 * $tilesBorderValue)}px;

/**
  * PRODUCT UNITS CARDS
  * These cards are styles as Dashboard Tiles
  * with the exception of the thicker top-border
  * size defined by `.pu-card:after` border-width
  * and border-color by the by PU states (below).
  */
.pu-card {
  &.v-card {
    position: relative;
    margin-top: var(--border-radius-lg);
    padding: var(--box-padding-dashboard);
    border: $tilesBorderWidth solid $tilesBorderColor;
    border-top: none; // top-border = .pu-card:after
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg) !important;
    background-color: var(--color-base-background) !important;
    cursor: pointer;
    font-size: 1vh;

    &.v-card {
      box-shadow: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(var(--border-radius-lg) * -1); // turn it in negative value
      left: -($tilesBorderWidth);
      width: calc(100% + #{$doubleTilesBordersWidth});
      height: var(--border-radius-lg);
      border: $tilesBorderWidth solid transparent;
      border-bottom-width: 0;
      border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    }

    .card-body {
      padding-bottom: 4px;

      &:not(.is-presenter) {
        padding-bottom: calc(var(--box-padding-dashboard) + 30px); // match v-btn height
      }

      &.is-fullscreen {
        height: calc(50vh - (200px + var(--border-radius-lg)));
      }
    }
    .card-footer {
      position: absolute;
      bottom: 0;
      width: calc(100% - var(--box-padding-dashboard) - var(--box-padding-dashboard));
      padding-bottom: var(--box-padding-dashboard);

      .btn-pu-details-control {
        .v-icon {
          transform: rotate(0deg);
        }
      }
    }

    /**
   * PU states
   */
    &.up:after {
      background-color: var(--color-uptime);
    }
    &.down_unjustified:after {
      background-color: var(--color-error);
    }
    &.down_unplanned:after {
      background-color: var(--color-justifiedDowntime);
    }
    &.down_planned:after {
      background-color: var(--color-plannedDowntime);
    }
    &.out_of_production:after {
      background-color: var(--color-outOfProduction);
      border-color: var(--color-border-theme); // untested on localhost
    }
    &.unknown:after {
      background-color: var(--color-unknown);
      border-color: var(--color-border-theme); // untested on localhost
    }
    &.carbon-frozen:after {
      // carbon frozen ? Yes, like Han Solo in The Empire Strikes Back :D
      background-color: inherit;
      border-color: var(--color-border-theme); // untested on localhost
    }

    // .pu-card.details-active
    &.details-active {
      background-color: var(--color-element-layer2) !important;
      box-shadow: var(--box-shadow-high-elevation);

      .card-footer {
        .btn-pu-details-control {
          background-color: var(--color-control-subtle-theme);

          .v-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
    /* .pu-card.disconnected */
    &.disconnected {
      background-color: var(--color-disconnected) !important;

      &:after {
        border-bottom: 0;
        background-color: var(--color-disconnected);
      }

      .wx-typo-h1 {
        display: inline;
        white-space: normal;
      }

      // Based on Martine comments it should not be visible
      .btn-pu-details-control {
        display: none;
      }

      // Light mode does NOT get text-shadow
      @at-root {
        .theme--dark {
          .wx-typo-h2 {
            text-shadow: var(--text-shadow-small-level);
          }
          .pu-subheader {
            text-shadow: var(--text-shadow-smallest-level);
          }
          .wx-typo-h1 {
            text-shadow: var(--text-shadow-high-level);
          }
        }
      }
    }
  }
}

// This weird CSS is to get "text-overflow: ellipsis" to work with a fluid width
.ellipsis {
  position: relative;

  &:before {
    content: "&nbsp;";
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.kpi-circle {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 10px solid transparent;

  &.is-fullscreen {
    font-size: 3vh;
  }

  &:not(.is-fullscreen) {
    font-size: 2vh;
  }

  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 50%;
  }

  @media ($wx-sm-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 120px;
      height: 120px;
      border: 12px solid transparent;
    }

    &:before {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
    }
  }

  @media ($wx-md-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 140px;
      height: 140px;
      border: 14px solid transparent;
    }

    &:before {
      width: calc(100% - 14px);
      height: calc(100% - 14px);
    }
  }

  @media ($wx-lg-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 160px;
      height: 160px;
    }

    &:before {
      width: calc(100% - 16px);
      height: calc(100% - 16px);
    }
  }

  @media ($wx-xl-min) {
    &.is-fullscreen {
      width: calc(50vh - 350px);
      height: calc(50vh - 350px);
    }
    &:not(.is-fullscreen) {
      width: 180px;
      height: 180px;
    }

    &:before {
      width: calc(100% - 25px);
      height: calc(100% - 25px);
    }
  }

  &.good {
    border-color: var(--color-success);
    &:before {
      background: var(--color-success-alpha);
    }
  }

  &.average {
    border-color: var(--color-warning);
    &:before {
      background: var(--color-warning-alpha);
    }
  }

  &.bad {
    border-color: var(--color-error);
    &:before {
      background: var(--color-error-alpha);
    }
  }

  &.no-metric {
    border-color: var(--color-neutral);
    &:before {
      background: var(--color-neutral-alpha);
    }
  }
}

.kpi-value,
.kpi-label {
  position: relative;
  z-index: 1;
}

.kpi-name {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.invisible-chip {
  visibility: hidden;
}
</style>
