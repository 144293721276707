<template>
  <article class="container-fluid">
    <h1 class="main-title mb-4">{{ $t("overview.title") }}</h1>
    <section>
      <wx-time-frame-date-picker
        ref="timeFrameDatePicker"
        v-if="activeFactory && !isFullScreen"
        :max-past-months="maxPastMonths"
        is-overview
      />

      <wx-select
        v-if="!isFullScreen"
        v-model="activeKpiSelection"
        :items="availableKpis"
        item-value="queryFor"
        item-text="title"
        @change="updateActiveKpi"
        return-object
        prepend-icon="$graphIcon"
        class="filter-style"
        hide-details
      />
    </section>

    <p-u-list v-if="isOverviewLoaded" :is-full-screen="isFullScreen" />
    <p-u-list-loader v-else />
  </article>
</template>

<script>
import PUList from "@/components/overview/PUList";
import WxSelect from "@/components/ui/WxSelect";
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import WxTimeFrameDatePicker from "@/components/ui/timeframedatepicker/WxTimeFrameDatePicker.vue";
import PUListLoader from "@/components/overview/PUListLoader.vue";

export default {
  name: "OverviewView",
  components: {
    PUListLoader,
    WxTimeFrameDatePicker,
    PUList,
    WxSelect,
  },
  data() {
    return {
      activeKpiSelection: 0,
      activeKpi: 0,
      fetchOverviewInterval: null,
      fetchDataSourceAlertsInterval: null,
    };
  },
  computed: {
    ...mapGetters("user", ["language", "isWorximityAdmin", "worximityAdminAllowedCoverageInMonths"]),
    ...mapGetters("overview", [
      "availableKpis",
      "activeKpiIndex",
      "activeProductionUnitIdForDetails",
      "isOverviewLoaded",
      "isFullScreen",
    ]),
    ...mapGetters("navigation", ["activeFactory", "isLiveData", "isTimeRangeReady"]),
    maxPastMonths() {
      if (this.isWorximityAdmin) {
        return this.worximityAdminAllowedCoverageInMonths;
      }
      return 1;
    },
    language() {
      return this.$i18n.locale;
    },
    productionDayMinutesFromMidnight() {
      if (this.activeFactory && this.activeFactory.productionDayMinutesFromMidnight) {
        return this.activeFactory.productionDayMinutesFromMidnight;
      } else {
        return 0;
      }
    },
    isCalendarDayBusinessDay() {
      if (this.activeFactory && this.activeFactory.isCalendarDayBusinessDay) {
        return this.activeFactory.isCalendarDayBusinessDay;
      } else {
        return false;
      }
    },
    timezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
  },
  watch: {
    isTimeRangeReady() {
      this.startFetch();
    },
  },
  methods: {
    ...mapActions("overview", [
      "fetchOverview",
      "setActiveKpiIndex",
      "fetchProductionUnitOverview",
    ]),
    ...mapActions("navigation", ["fetchFactoryDataSourceAlerts"]),
    updateActiveKpi(event) {
      this.activeKpiSelection = event;
      const index = this.availableKpis.findIndex((tab) => tab === event);
      if (index >= 0) {
        this.activeKpi = index;
      } else {
        this.activeKpi = 0;
      }
      this.startFetch();
    },
    startFetchOverview() {
      if (!this.activeFactory) return;
      this.setActiveKpiIndex(this.activeKpi);
      clearInterval(this.fetchOverviewInterval);
      if (this.activeFactory) {
        this.fetchOverview();
        this.fetchOverviewInterval = setInterval(() => {
          this.fetchOverview();
          if (this.activeProductionUnitIdForDetails) {
            this.fetchProductionUnitOverview(this.activeProductionUnitIdForDetails);
          }
        }, this.getRefreshDelay());
      }
    },
    getRefreshDelay() {
      if (this.isLiveData) {
        return 30000; // 30 seconds
      } else {
        return 300000; // 5 minutes
      }
    },
    startFetchFactoryDataSourceAlerts() {
      clearInterval(this.fetchDataSourceAlertsInterval);
      this.fetchFactoryDataSourceAlerts();
      this.fetchDataSourceAlertsInterval = setInterval(() => {
        this.fetchFactoryDataSourceAlerts();
      }, 60000);
    },
    startFetch() {
      this.startFetchFactoryDataSourceAlerts();
      this.startFetchOverview();
    },
  },
  beforeMount() {
  },
  mounted() {
    this.activeKpiSelection = this.availableKpis[0];
  },
  beforeDestroy() {
    clearInterval(this.fetchOverviewInterval);
    clearInterval(this.fetchDataSourceAlertsInterval);
  },
};
</script>

<style lang="scss" scoped>
.loading {
  height: 100%;
}
.dropdown-nav,
.filter-style {
  min-width: 14rem;
}
.dropdown-nav {
  flex-grow: 0;
  max-width: 20rem;

  @media ($wx-xl-min) {
    max-width: 22rem;
  }

  .time-selector-option {
    font-weight: 700;
  }
  .time-selector-date {
    color: var(--color-text-subtle-theme);
  }
}
.filter-style {
  max-width: 20rem;

  @media ($wx-sm-min) {
    max-width: 15rem;
  }
}
</style>
