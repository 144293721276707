<template>
  <article class="pu-details-downtimes wx-tile-panel">
    <h3 v-if="!isXs" class="mb-6 text-center">{{ $t("overview.details.downtimes.title") }}</h3>

    <v-chip :color="productionUnitDowntimes.downtimesToJustify > 0 ? 'error' : 'success'" class="mb-4 to-justify-chip"
      >{{ productionUnitDowntimes.downtimesToJustify }} {{ $t("overview.details.downtimes.toJustify") }}</v-chip
    >

    <div class="downtime-container">
      <section class="dt-listings dt-unplanned">
        <h4 class="list-heading">{{ $t("overview.details.downtimes.unplanned") }}</h4>
        <ul class="list" v-if="productionUnitDowntimes.unplannedDowntimes.length > 0">
          <li
            v-for="(downtime, index) in productionUnitDowntimes.unplannedDowntimes"
            :key="'unplannedDowntimes-' + index"
            class="dt-item item-unplanned wx-typo-sm mb-2"
          >
            <span class="mr-2">{{ convertSeconds(downtime.durationSec) }}</span>
            <span class="wx-subtle-text ellipsis">{{ downtime.reason }}</span>
          </li>
        </ul>
        <p class="no-list dt-item wx-subtle-text" v-else>{{ $t("overview.details.downtimes.noUnplanned") }}</p>
      </section>

      <section class="dt-listings dt-planned">
        <h4 class="list-heading">{{ $t("overview.details.downtimes.planned") }}</h4>
        <ul class="list" v-if="productionUnitDowntimes.plannedDowntimes.length > 0">
          <li
            v-for="(downtime, index) in productionUnitDowntimes.plannedDowntimes"
            :key="'plannedDowntimes-' + index"
            class="dt-item item-planned wx-typo-sm mb-2"
          >
            <span class="mr-2">{{ convertSeconds(downtime.durationSec) }}</span>
            <span class="wx-subtle-text ellipsis">{{ downtime.reason }}</span>
          </li>
        </ul>
        <p v-else class="no-list dt-item wx-subtle-text">{{ $t("overview.details.downtimes.noPlanned") }}</p>
      </section>
    </div>
  </article>
</template>

<script>
import { Duration } from "luxon";
import { mapGetters } from "vuex";

export default {
  name: "PUDetailsDowntimes",
  computed: {
    ...mapGetters("overview", ["productionUnitDowntimes"]),
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    convertSeconds(seconds) {
      return Duration.fromMillis(seconds * 1000).toFormat("hh:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.pu-details-downtimes {
  .dt-listings {
    .list-heading {
      margin-bottom: 0.5rem;
    }
    .dt-item {
      padding-top: 0.25rem;
      background-color: var(--color-element-layer2);
      border-radius: var(--border-radius-sm);
    }
    .list,
    .no-list {
      margin-bottom: 1rem;
    }
    .no-list {
      color: var(--color-text-subtle-theme);
      background-color: var(--color-element-layer2);
    }
  }
}

.downtime-container {
  @media ($wx-sm-min) {
    display: flex;

    .dt-unplanned {
      width: calc((100% - var(--box-padding-dashboard)) / 2);

      &:first-child {
        margin-right: var(--box-padding-dashboard);
      }
    }
  }

  @media ($wx-md-min) {
    display: block;

    .dt-unplanned {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.list .dt-item {
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &.item-unplanned {
    &::before {
      background-color: var(--color-justifiedDowntime);
    }
  }

  &.item-planned {
    &::before {
      background-color: var(--color-plannedDowntime);
    }
  }
}

.to-justify-chip::before {
  display: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
